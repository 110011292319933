.site-header {
    position: relative;
    padding: 24px 0;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 500px;
    }
}

.site-header-inner {
    position: relative; /* To display all elements above the background color */
    display: flex;
    justify-content: space-between;
    align-items: center;

    .brand {
        width: 220px;
    }
}

.header-links {
    display: flex;
    align-items: center;
    li {
        display: inline-flex;
    }

    a:not(.button) {
        @include font-size(9, mobile, true, true, true);
        @if ( get-font-size(9, desktop) != get-font-size(9, mobile) ) {
            @include media( '>medium' ) {
                @include font-size(9, desktop, true, true, true);
            }
        }
        @include font-weight(bold);
        @include anchor-aspect(header);
        line-height: 16px;
        padding: 8px 24px;
    }
}

@include media( '>medium' ) {

    .site-header {

        &::before {
            height: 640px;
        }
    }
}
