.button {
    display: inline-flex;
    @include font-size(9, mobile, true, false, true);
    @if ( get-font-size(9, desktop) != get-font-size(9, mobile) ) {
        @include media( '>medium' ) {
            @include font-size(9, desktop, true, false, true);
        }
    }
    @include font-weight(bold);
    line-height: 16px;
    text-transform: uppercase;
    text-decoration: none !important;
    background-color: color(bg, 1);
    color: color(primary, 1) !important;
    border: none;
    border-radius: 2rem;
    cursor: pointer;
    justify-content: center;
    padding: 16px 32px;
	height: 48px;
    text-align: center;
    white-space: nowrap;

    &:active {
        outline: 0;
    }

    &::before {
        border-radius: 2rem;

    }
}

.button-shadow {
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        box-shadow: 0 8px 24px rgba(color(typography, 1), .12);
        mix-blend-mode: multiply;
        transition: box-shadow .15s ease;
    }

    &:hover {

        &::before {
            box-shadow: 0 8px 24px rgba(color(typography, 1), .25);
        }
    }
}

.button-sm {
    padding: 8px 24px;
	height: 32px;

    &.button-shadow {

        &::before {
            box-shadow: 0 4px 16px rgba(color(typography, 1), .12);
        }

        &:hover {

            &::before {
                box-shadow: 0 4px 16px rgba(color(typography, 1), .25);
            }
        }
    }
}

.button-primary,
.button-secondary {
    color: color(typography, 1i) !important;
    transition: background .15s ease;
}

.button-primary {
    background: mix(color(primary, 1), color(primary, 2));
    background: linear-gradient(65deg, color(primary, 1) -25%, color(primary, 2) 100%);

    &:hover {
        background: lighten(mix(color(primary, 1), color(primary, 2)), .5%);
        background: linear-gradient(65deg, lighten(color(primary, 1), .5%) -25%, lighten(color(primary, 2), .5%) 100%);
    }
}

.button-secondary {
    background: #00d9a6;
    background-image: linear-gradient(39deg,#00d9a6 0%,#9be15d 100%);

    &:hover {
        background:  #00d9a6;
        background: linear-gradient(39deg, lighten(#00d9a6, .5%) -25%, lighten(#9be15d, .5%) 100%);
    }
}

.button-block {
    display: flex;
}
