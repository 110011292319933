// --------------------------------------------
// Colors -------------------------------------
// Usage example: color(primary, main)
// --------------------------------------------
$color: (
	typography: (
		1: #202B36,
		2: #5B6F82,
		3: #92A2B1,
		1i: #FFF,
		2i: rgba( #FFF, .8 )
	),
	bg: (
		1: #FFFFFF,
		2: #F6F8FA,
		3: #E3E7EB
	),
	primary: (
		1: #00d9a6,
		2: #00CFEB,
		3: #007585,
 	),
 	secondary: (
		1: #9be15d,
		2: #00F9D2,
		3: #00937C
 	),
 	additional: (
		1: #00C6A7,
		2: #1E4D92
 	)
);

// --------------------------------------------
// Typography ---------------------------------
// --------------------------------------------
$font__family: (
	base: '"Raleway", sans-serif', // font-family(base)
	heading: '"Raleway", serif', // font-family(heading)
	code: 'Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace', // font-family(code)
	pre: '"Courier 10 Pitch", Courier, monospace' // font-family(pre)
);

$font__sizes: (
	alpha:   ( 42px, 52px, -0.1px ), // font-size, line-height, kerning (use '0' if don't want to output any kerning)
	beta:    ( 36px, 46px, -0.2px ),
	gamma:   ( 30px, 40px, -0.1px ),
	delta:   ( 20px, 30px, -0.1px ),
	epsilon: ( 18px, 27px, -0.1px ),
	zeta:    ( 16px, 24px, -0.1px ),
	eta:     ( 14px, 20px, 0px ),
	theta:   ( 13px, 18px, 0px )
);

$font__scale: (
	desktop: (                             // i.e. $breakpoint__m + $breakpoint__l (600 - 1024)
		1: map-get($font__sizes, alpha),   // H1
		2: map-get($font__sizes, beta),    // H2
		3: map-get($font__sizes, gamma),   // H3
		4: map-get($font__sizes, delta),   // H4, H5, H6
		5: map-get($font__sizes, delta),   // Body
		6: map-get($font__sizes, epsilon), // Text small (e.g. features description)
		7: map-get($font__sizes, zeta),    // Text smaller (e.g. pricing table's lists)
		8: map-get($font__sizes, eta),     // Footer area
		9: map-get($font__sizes, theta)    // Header links, buttons
	),
	mobile: (                              // i.e. $breakpoint__xs + $breakpoint__s (up to 600)
		1: map-get($font__sizes, beta),    // H1
		2: map-get($font__sizes, gamma),   // H2
		3: map-get($font__sizes, delta),   // H3
		4: map-get($font__sizes, epsilon), // H4, H5, H6
		5: map-get($font__sizes, epsilon), // Body
		6: map-get($font__sizes, epsilon), // Text small (e.g. features description)
		7: map-get($font__sizes, zeta),    // Text smaller (e.g. pricing table's lists)
		8: map-get($font__sizes, eta),     // Footer area
		9: map-get($font__sizes, theta)    // Header links, buttons
	)
);

$font__weight: (
	regular: 400, 	// font__weight(regular)
	medium: 500,	// font__weight(medium)
	semibold: 600,	// font__weight(semi-bold)
	bold: 800		// font__weight(bold)
);

// --------------------------------------------
// Structure ----------------------------------
// --------------------------------------------
$content__padding: (
	mobile: 16px,
	desktop:  24px
);
$container__width: 1080px;
$container__width-sm: 800px;
